import {Alert, Box, Modal} from "@mui/material";
import React from "react";
import {
    AddBoorprofiel,
    AddCadastralInfo,
    AddContact,
    AddControle,
    AddLegalEntity,
    AddLegalEntityMeasurement,
    AddNote,
    AddPermit,
    AddSystem,
    AddWell,
    AuthToken,
    baseUrl,
    Delete,
    UpdateBoorprofiel,
    UpdateCadastralInfo,
    UpdateContact,
    UpdateControle,
    UpdateEnergySystem,
    UpdateEnergySystemStatus,
    UpdateEnergySystemStatusToezicht,
    UpdateLegalEntity,
    UpdateLegalEntityMeasurement,
    UpdateNote,
    UpdatePermit,
    UpdateWell
} from "../constants";
import {useOnttrekkingenContext} from "../context/OnttrekkingenContextProvider";
import BoorprofielForm from "../forms/BoorprofielForm";
import BronForm from "../forms/BronForm";
import CadastralForm from "../forms/CadastralForm";
import ContactForm from "../forms/ContactForm";
import ControleForm from "../forms/ControleForm";
import DeleteForm from "../forms/DeleteForm";
import LegalEntityForm from "../forms/LegalEntityForm";
import SystemForm from "../forms/SystemForm";
import EnergySystemStatusToezichtForm from "../forms/EnergySystemStatusToezichtForm";
import LegalEntityMeasurementForm from "../forms/LegalEntityMeasurementForm";
import NotitieForm from "../forms/NotitieForm";
import PermitForm from "../forms/PermitForm";
import SystemStatusForm from "../forms/SystemStatusForm";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    maxHeight: "80vh",
    overflowY: "auto",
    overflowX: "auto",
    transform: 'translate(-50%, -50%)',
    maxWidth: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const UpsertObjectModal = () =>
{
    const {setModalVisible, modalVisible,modalAction,
        alertText, setAlertText,
        alertVisible,setAlertVisible} = useOnttrekkingenContext()
    const handleClose = () => {setModalVisible(false); setAlertVisible(false)}

    function CurrentForm()
    {
        switch (modalAction)
        {
            case AddLegalEntity:
            case UpdateLegalEntity:
                return <LegalEntityForm/>

            case AddContact:
            case UpdateContact:
                return <ContactForm/>

            case AddCadastralInfo:
            case UpdateCadastralInfo:
                return <CadastralForm/>

            case AddSystem:
            case UpdateEnergySystem:
                return <SystemForm />

            case UpdateEnergySystemStatus:
                return <SystemStatusForm />

            case UpdateEnergySystemStatusToezicht:
                return <EnergySystemStatusToezichtForm/>

            case AddPermit:
            case UpdatePermit:
                return <PermitForm/>

            case AddWell:
            case UpdateWell:
                return <BronForm/>

            case AddBoorprofiel:
            case UpdateBoorprofiel:
                return <BoorprofielForm/>

            case AddControle:
            case UpdateControle:
                return <ControleForm />

            case AddNote:
            case UpdateNote:
                return <NotitieForm/>

            case AddLegalEntityMeasurement:
            case UpdateLegalEntityMeasurement:
                return <LegalEntityMeasurementForm/>


            case Delete:
                return <DeleteForm/>
        }
    }

    return (<>
        <Modal
            open={modalVisible}
            onClose={handleClose}
        >
            <Box sx={style}>

                <Alert style={{
                    display: alertVisible
                             ? "flex"
                             : "none"
                }} severity="error" color={"warning"}>{alertText}</Alert>

                <html ig-base-url={baseUrl + "/ig"} ig-api-token={AuthToken}>
                {CurrentForm()}

                </html>
            </Box>
        </Modal>
    </>
)
}
export default UpsertObjectModal
